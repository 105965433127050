
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { message, Empty } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { getParkDetail, addPark, overviewCourseList, editPark } from '@/api/index'
import { DeleteOutlined, PlusCircleOutlined, SwapOutlined, RightOutlined, DownOutlined } from '@ant-design/icons-vue'
import uploadAb from '@/views/encyclopediasManger/components/upload-ab.vue'
import { SEND_TYPE, OSSURL, OSS_ICON } from '@/hooks/ali-oss'
import { useRouter } from 'vue-router'
export interface FilesProps {
  file_name: string;
  file_path: string;
  [key: string]: any;
}
export interface FileProps {
  file?: FilesProps;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
interface AnchorProps {
  key: string;
  course_ids: [];
}
export default defineComponent({
  name: 'CreateOverview',
  props: ['id'],
  components: {
    // SwapOutlined,
    uploadAb,
    // DeleteOutlined,
    // PlusCircleOutlined,
    RightOutlined,
    DownOutlined
  },
  emits: ['changeIndex'],
  setup (props, context) {
    const router = useRouter()
    const Id = computed(() => { return props.id })
    const upAbwList = ref([])
    const upAbaList = ref([])
    const upAbiList = ref([])
    // 表单
    const formRef = ref()
    const formState = reactive({
      name: '',
      abList: []
    })
    const rules = {
      name: [
        { required: true, message: '请输入园区名称', trigger: 'blur', whitespace: true }
      ],
      abList: [
        { message: '必填一项', trigger: 'blur', whitespace: true }
      ]
    }
    const courseList = ref([])
    const getCourseList = () => {
      overviewCourseList({}).then(res => {
        courseList.value = res.data.map((item: any) => {
          return {
            value: item.id,
            key: `${item.id}-${item.name}`,
            label: `${item.id}-${item.name}`
          }
        })
      })
    }
    const uploadAbw = ref()
    const uploadAbi = ref()
    const uploadAba = ref()
    // ab包
    const abwIsOpen = ref(false)
    const openAbwContent = () => {
      abwIsOpen.value = !abwIsOpen.value
    }
    const abaIsOpen = ref(false)
    const openAbaContent = () => {
      abaIsOpen.value = !abaIsOpen.value
    }
    const abiIsOpen = ref(false)
    const openAbiContent = () => {
      abiIsOpen.value = !abiIsOpen.value
    }

    const abwList: any = ref([])
    const abwUpObj = ref([SEND_TYPE[5]])
    const changeFile = (lists: any, oladList: any, upLoadList: any) => {
      const isUploaded = oladList.every((item: any) => Object.prototype.hasOwnProperty.call(item, 'id'))
      if (isUploaded) {
        oladList.unshift(...lists)
      } else {
        oladList.splice(0, 1, ...lists)
      }
      upLoadList = lists
      console.log(upLoadList, '123')
    }
    const handleDelete = (index: number, oladList: any, upLoadList: any) => {
      oladList.splice(index, 1)
      upLoadList.splice(0, 1)
    }

    const abaList = ref([])
    const abaUpObj = ref([SEND_TYPE[6]])

    const abiList = ref([])
    const abiUpObj = ref([SEND_TYPE[7]])

    // 删除文件
    const deleteFile = (list: FileProps, item: number) => {
      const _index = list.findIndex((ele: any) => ele === item)
      list.splice(_index, 1)
    }

    // 重置list值
    const resetList = (lists: any) => {
      return lists.map((ele: FileProps) => {
        return {
          type: ele.file_type ? ele.file_type : ele.type,
          file_name: ele.file_name ? ele.file_name : ele.name,
          file_path: ele.file_path ? ele.file_path : ele.file_path
        }
      })
    }
    // 添加总览请求
    const postOverviewHttp = (abList: any) => {
      addPark({
        name: formState.name,
        ab: abList
      }).then(res => {
        message.success('创建成功')
        context.emit('changeIndex', 0)
      })
    }

    // 修改总览请求
    const putOverviewHttp = (abList: any) => {
      editPark({
        id: props.id,
        name: formState.name,
        ab: abList
      }).then(res => {
        message.success('修改成功')
        router.push({ name: 'ParkManage' })
      })
    }
    // 添加或修改
    const fetchSave = async (name: any, abList: any) => {
      if (upAbwList.value.length === 0 && upAbiList.value.length === 0 && upAbaList.value.length === 0) {
        name(abList)
      } else {
        if (upAbwList.value.length) {
          await uploadAbw.value.fetchSend()
        }
        if (upAbiList.value.length) {
          await uploadAbi.value.fetchSend()
        }
        if (upAbaList.value.length) {
          await uploadAba.value.fetchSend()
        }
        name(abList)
      }
    }
    // 提交
    const saveSubmit = () => {
      formState.abList = resetList(upAbwList.value.concat(upAbiList.value, upAbaList.value))
      formRef.value.validate().then(() => {
        if (props.id) {
          // 修改总览
          fetchSave(putOverviewHttp, formState.abList)
        } else {
          if (formState.abList.length > 0) {
            // 添加总览
            fetchSave(postOverviewHttp, formState.abList)
          } else {
            message.error('总览资源必填一项')
          }
        }
      }).catch((error: ValidateErrorEntity) => {
        console.log('error', error)
      })
    }

    // 获取总览
    const getOverviewHttp = () => {
      getParkDetail({
        id: props.id
      }).then(res => {
        if (!res.data) {
          return false
        }
        formState.name = res.data.name
        abaList.value = res.data.aba ? res.data.aba : []
        abwList.value = res.data.abw ? res.data.abw : []
        abiList.value = res.data.abi ? res.data.abi : []
      })
    }
    const handleChange = (value: string[]) => {
      console.log(`selected ${value}`)
    }
    const filterCourse = (input: string, option: any) => {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 复制下载地址
    const copyPath = (shareLink: any) => {
      const input = document.createElement('input')
      input.value = shareLink
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      message.success('复制成功')
    }
    onMounted(() => {
      getCourseList()
      // addAnchorList()
      if (props.id) {
        getOverviewHttp()
      } else {
        // addAnchorList()
      }
    })
    return {
      Id,
      filterCourse,
      courseList,
      handleChange,
      OSSURL,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      activeKey: ref('1'),
      uploadAba,
      uploadAbi,
      uploadAbw,
      abiUpObj,
      abiList,
      abaUpObj,
      abaList,
      abwUpObj,
      abwList,
      deleteFile,
      saveSubmit,
      formRef,
      formState,
      rules,
      abwIsOpen,
      openAbwContent,
      abaIsOpen,
      openAbaContent,
      abiIsOpen,
      openAbiContent,
      handleDelete,
      changeFile,
      upAbwList,
      upAbaList,
      upAbiList,
      copyPath
    }
  }
})
